/* FlipBookStyles.css */
.flip-book {
  width: 100%;
  height: 100%;
  max-height: 600px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  /* margin: 0 auto; */
  border-radius: 5px;
  overflow: hidden;
  /* perspective: 1200px; */
}

.flip-book .flip-page {
  position: relative;
  width: 100%;
  /* height: 100%; */
  /* background-color: #fff; */
  font-family: "Georgia", serif;
  font-size: 14px;
  text-align: justify;
  line-height: 1.5;
  color: #333;
  padding: 0px 0px;
  box-sizing: border-box;
}

.flip-book .flip-page-content {
  width: 100%;
  height: 100%;
  overflow: auto;
  text-transform: capitalize;
}

.flip-book .flip-page .page-number {
  position: absolute;
  bottom: 10px;
  right: 30px;
  font-size: 12px;
  color: #666;
}

.flip-book .flip-page .page-title {
  font-size: 20px;
  margin-bottom: 20px;
  font-weight: bold;
  text-align: center;
}

.first-component {
  background-color: #0093e9;
  background-image: linear-gradient(45deg, #0093e9 0%, #d9ffff 100%);
}
